import { createTheme } from "@mui/material/styles";

const createCustomTheme = (scalingFactor = 1) =>
  createTheme({
    palette: {
      primary: {
        main: "#577CF9", // Primary color
      },
      secondary: {
        main: "#9FAFB8", // Secondary color
      },
      text: {
        primary: "#222", // Primary text color
        secondary: "#333", // Secondary text color
      },
    },
    typography: {
      fontFamily: "Varela Round",
      h1: {
        fontSize: `${4.3 * scalingFactor}rem`,
        fontWeight: "bold",
        fontStyle: "italic",
      },
      h2: {
        fontSize: `${4 * scalingFactor}rem`,
        fontWeight: "bold",
        color: "#222",
      },
      h3: {
        fontSize: `${2 * scalingFactor}rem`,
      },
      h4: {
        fontSize: `${1.7 * scalingFactor}rem`,
      },
      h5: {
        fontSize: `${1.5 * scalingFactor}rem`,
      },
      h6: {
        fontSize: `${1.3 * scalingFactor}rem`,
        color: "#222",
        fontWeight: "bold",
        padding: "5px 10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "50px",
      },
      body1: {
        fontSize: `${1 * scalingFactor}rem`,
        color: "#666",
      },
      body2: {
        fontSize: `${1 * scalingFactor}rem`,
      },
      mobileH1: {
        fontSize: `${2.5 * scalingFactor}rem`,
        fontWeight: "bold",
        fontStyle: "italic",
      },
      mobileH2: {
        fontSize: `${2 * scalingFactor}rem`,
        fontWeight: "bold",
        color: "#222",
      },
      mobileH3: {
        fontSize: `${1.3 * scalingFactor}rem`,
      },
      mobileH4: {
        fontSize: `${1.2 * scalingFactor}rem`,
      },
      mobileH5: {
        fontSize: `${1.1 * scalingFactor}rem`,
      },
      mobileH6: {
        fontSize: `${1 * scalingFactor}rem`,
        color: "#222",
        fontWeight: "bold",
        padding: "5px 10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "50px",
      },
      mobileBody1: {
        fontSize: `${0.9 * scalingFactor}rem`,
      },
      mobileBody2: {
        fontSize: `${0.85 * scalingFactor}rem`,
      },
    },
  });

export default createCustomTheme;
