import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import theme from "./theme/theme";
import { ContentProvider } from "./components/reusable/useContent";
import { AccessibilityProvider } from "./context/AccessibilityContext";

// Create an RTL cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContentProvider>
      <CacheProvider value={cacheRtl}>
          <CssBaseline />
          <AccessibilityProvider>
            <App />
          </AccessibilityProvider>
      </CacheProvider>
    </ContentProvider>
  </React.StrictMode>
);

reportWebVitals();
